.view-recent-parent {
  padding: 0;
  display: flex;
  width: 100%;
}

.container-view-like-parent {
  margin-top: 20px;
}

.view-recent-child-left {
  border: 1px solid transparent;
  box-shadow: 0 0 10px rgba(135, 134, 134, 0.5);
  border-radius: 0.22rem 0.22rem 0.22rem;
  padding: 3px;
}

.estate-viewest-title {
  padding: 8px 0 5px 8px;
  line-height: 1;
  border-left: 4px solid #FF6800;
  font-size: 20px;
  font-weight: bold;
  color: #364247;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  background-color: #f7fafa;
}

a.link-to-view-recent-page:hover {
  text-decoration: none;
  border: 1px solid #FF6800;
  border-radius: 4px;
}
.view-recent-child-right {
  margin-left: 20px;
  display: flex;
  width: 100%;
}

.view-recent-child-right_column1 {
  width: 38%;
  display: inline-grid;
}

.view-recent-child-right_column2 {
  width: 30%;
}

.view-recent-child-right_column3 {
  width: 20%;
}

.view-recent-child-right_area{
  width: 120px;
  color: #212529;
  font-size: medium;
}

.view-recent-child-right_construct {
  width: 25%;
  color: #212529;
  font-size: medium;
}

span.view-recent-child-right_cost {
  color: #FF6800;
  font-weight: bold;
  font-size: x-large;
  height: 10px;
}

.view-recent-child-right_address{
  color: #212529;
  font-size: medium;
}

a.link-to-view-recent-page {
  display: flex;
  padding: 20px;
  border-radius: 4px;
}

.view-recent-title {
  padding-top: 15px;
  margin-bottom: 10px;
  display: flex;
}

.view-recent-h5 {
  padding: 3px 0 3px 8px;
  line-height: 1;
  font-size: 20px;
  font-weight: bold;
  color: #364247;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.view-recent-photo-lefthand {
  width: 110px;
  height: 90px;
}

.view-recent-photo {
  margin-bottom: 6px;
  background-color: #fff;
  text-align: center;
  cursor: pointer;
  overflow: hidden;
  width: -moz-fit-content;
  width: fit-content;
}

.view-recent-photo img {
  width: 110px;
  height: 90px;
  border-radius: 3px;
}

.page-container-common {
  /* background-color: rgb(255, 255, 255); */
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  margin-left: 18%;
  margin-right: 18%;
  margin-top: 100px;
}

.container-view-recent-child{
  border-radius: 4px;
  border: 0.5px solid #d8c8c8;
}
.div-between {
  height: 10px;
  background-color: #f7fafa;
  border-color: #f7fafa;
}

.fa-heard{
  position: relative;
  right: -71px;
  top: -1px;
}

.fa-heard-title {
  margin-top: 12px;
  margin-bottom: 15px;
  margin-right: 15px;
}

.fa-heard:hover {
  fill: #FF6800 !important;
}

.title-viewest-liked {
  font-weight: bold;
  font-size: large;
}

.no-record-like-exist {
  background: #ffe1e19c;
  padding: 30px;
  margin-bottom: 40%;
}