.pagination .visually-hidden {
  display: none;
}

.pagination {
  margin-top: 10px;
  justify-content: center;
  margin-bottom: 0;
}

.page-item.disabled .page-link {
  color: black;
}

.page-item .page-link {
  color: black;
}

.page-item.active .page-link {
  background-color: #f7fafa;
  border-color: #FF6800;
  color: #FF6800;
}