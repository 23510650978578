@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500&display=swap');

/* FF6800 cam dam */
/* FF6800 cam nhat */
body {
  background-color: #f7fafa !important;
  padding-top: 0px;
  margin: 0;
  font-size: 16px;
  font-family: Arial;
  position: relative;
  min-height: 100vh;
}

.MuiAppBar-positionFixed{
  display: none !important;
  position: relative !important;
  left: -7px !important;
  top:  -15px !important
}

.user-circle-icon {
  margin-top: -2px;
  position: relative;
  right: 170px;
  width: 15px;
}

h1,
h2,
p,
ul,
li {
  font-family: Arial;
  font-weight: 200;
}

ul.header li {
  display: inline;
  list-style-type: none;
  margin: 0;
}

ul.header li a {
  color: #FFF;
  text-decoration: none;
  padding: 7px;
  display: inline-block;
  font-weight: 600;
}


.username {
  color: #583d22;
}


.publish-modal {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
}

.publish-modal.is-active {
  display: block;
}

.publish-modal-background {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
}

.publish-modal-content {
  position: absolute;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
  width: 80%;
  max-width: 400px;
  background: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 2;
}

.publish-modal-header-error {
  background: rgb(250, 3, 3);
  padding: 10px 20px;
  font-size: 24px;
  color: #fff;
}

.publish-modal-header-susscess {
  background: rgb(12, 197, 46);
  padding: 10px 20px;
  font-size: 24px;
  color: #fff;
}

.publish-modal-body {
  padding: 15px 20px;
  font-size: 16px;
}

.publish-modal-footer {
  background: #eee;
  text-align: right;
  padding: 10px 20px;
  border-top: 1px solid #ddd;
}

  button#close-model {
    border: none;
    background: #17A2B8;
    color: #fff;
    padding: 10px 22px;
    border-radius: 3px;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 600;
    border: 3px solid #17A2B8;
    font-size: 14px;
    cursor: pointer;

    &:hover {
      background: #fff;
      color: #17A2B8;
      border: 3px solid #17A2B8;
    }
  }

.container-div-right-publish {
  position: relative;
  right: 0;
}

.div-right-publish {
  background-color: #FF6800;
  padding-top: 9.5px;
  padding-bottom: 9.5px;
  padding-right: 20px;
  padding-left: 20px;
  color: #fff;
  border-radius: 5px;
  font-size: medium;
  border: 1px solid #FF6800;
  font-weight: 400;
}

.div-right-register {
  background-color: #fff;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 20px;
  padding-left: 20px;
  color: #FF6800;
  border-radius: 5px;
  font-size: medium;
  border: 1px solid #FF6800;
  margin-right: -20px;
}

.div-right-logout {
  background-color: #fff;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 20px;
  padding-left: 20px;
  color: #FF6800;
  border-radius: 5px;
  font-size: medium;
  border: 1px solid #FF6800;
  margin-right: -20px;
}

.container-right {
  display: flex;
  position: absolute;
  top: 20px;
  right: 0;
}

.logout_login-btn {
  display: flex;
  position: relative;
}

.div-right-login {
  background-color: #fff;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 20px;
  padding-left: 20px;
  color: #FF6800;
  border-radius: 5px;
  font-size: medium;
  border: 1px solid #FF6800;
  margin-right: -20px;
}

div.register {
  color: #fff;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 20px;
  padding-left: 20px;
  color: #FF6800;
  border-radius: 5px;
  font-size: medium;
  border: 1px solid #FF6800;
  position: absolute;
  right: 15%;
  top: 17px;
}

.page-container-publish {
  background-color: rgb(255, 255, 255);
  box-shadow: rgb(0 0 0 / 15%) 0px 1px 4px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  margin-left: 18%;
  margin-right: 18%;
  margin-top: 90px;
  margin-bottom: 15px;
}

.page-container-for-rent-invest {
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  margin-left: 18%;
  margin-right: 18%;
  margin-top: 90px;
}

.page_container_search {
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 30px;
  padding-bottom: 25px;
  margin-top: 50px;
  margin-left: 17%;
  margin-right: 17%;
}

.page_content {
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  padding: 15px;
}

.no-result-search {
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  padding-top: 10px;
  height: 450px;
}

.page_content_sell {
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
}

ul.header-content {
  padding-left: 68.5%;
  margin-top: 5px;
}

.header-content li {
  border-width: 1px;
  display: inline-grid;
  border-style: solid;
  border-color: #ada9a7;
  border-top: #FFF;
  border-bottom: #FFF;
  border-right: #FFF;
  font-size: small;
  text-align: center;
  align-content: center;
  width: 20%;
}

.svg-inline--fa {
  padding-left: 33px;
}

.header-content li:last-child {
  border-width: 1px;
  border-style: solid;
  border-right-color: #ada9a7;
}

.header-content span.searchsCondition {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 7px;
}

.div-header-content {
  display: flex;
  position: relative;
  top: 10px;
  right: 80px;  
}

.likes-estates-recent {
  margin-left: 30px;
}

.header-content li span.countViewHistory,
.display-count-number-in-up_view {
  align-items: center;
  background-color: #ffc95c;
  border-radius: 8px;
  box-shadow: 0 0 0 1px #fff;
  display: flex;
  font-size: .625rem;
  font-weight: inherit;
  height: 16px;
  justify-content: center;
  min-width: 16px;
  position: absolute;
  right: 55px;
  top: -2px;
  z-index: 1;
}

.header-content li span.countViewHistory,
.display-count-number-in-up_like {
  align-items: center;
  background-color: #ffc95c;
  border-radius: 8px;
  box-shadow: 0 0 0 1px #fff;
  display: flex;
  font-size: .625rem;
  font-weight: inherit;
  height: 16px;
  justify-content: center;
  min-width: 16px;
  position: absolute;
  right: -5px;
  top: -2px;
  z-index: 1;
}

.siteHeader-inner {
  width: 50px;
  height: 50px;
  position: relative;
  top: 15px;
  display: -webkit-inline-box;
}

.fix-header-item {
  position: fixed;
  top: 0;
  left: 0;
  right: 0; 
  background-color: #fff; 
}

.footer-div {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0; 
  background-color: #fff; 
  z-index: 1000;
}

.container-header:before {
  content: "";
  display: block;
  position: absolute;
  top: -5px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% + 2px);
  height: 13px;
  background: #FF6800;
  border-radius: 0.22rem 0.22rem 0 0;
}


.container-content {
  background-color: #f7fafa;
  font-family: Arial;
  height: min-content;
}

.logo img {
  margin-top: -7px;
  width: 170px;
  height: 36px;
}

.navbar-light .navbar-nav .nav-link {
  margin-top: 40px;
  color: rgb(0 0 0 / 99%) !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  font-size: 110% !important;
}

svg#faRegHeart-icon {
  position: relative;
  top: -10px;
  right: -10px
}

svg#faRegClock-icon {
  position: relative;
  top: -10px;
  right: -40px
}


.container-publish-button {
  position: relative;
  right: -150px;
  top: 0px;
}

.nav-link {
  width: max-content;
  font-weight: 500;
  font-size: medium;
  color: rgb(0, 0, 0);
  padding: 1em;
}

.tabs-search {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 173.50px;
  background: #f1f1f1;
  margin-left: 0px auto 0;
  word-break: break-all;
  border: 1px solid rgb(22 64 59 / 27%);
}

.loading-spiner .absolute {
  position: absolute;
  top: 200px;
  left: 46%;
}

.bloc-tabs {
  display: flex;
  background: white;
}

.tabs {
  line-height: 3px;
  padding: 15px;
  text-align: center;
  width: 50%;
  background: rgba(128, 128, 128, 0.075);
  cursor: pointer;
  border-bottom: 1px solid rgba(0, 0, 0, 0.274);
  box-sizing: content-box;
  position: relative;
  outline: none;
}

.tabs:not(:last-child) {
  border-right: 1px solid rgba(0, 0, 0, 0.274);
}

.active-tabs {
  border-bottom: 1px solid transparent;
  background-image: linear-gradient(to right top, #beacb8, #bfeeff);
}

.active-tabs::before {
  content: "";
  display: block;
  position: absolute;
  top: -5px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% + 2px);
  height: 5px;
  background: #FF6800;
}

button {
  border: none;
}

.content-tabs {
  flex-grow: 1;
  height: auto;
}

.content {
  background-image: linear-gradient(to right top, #beacb8, #bfeeff);
  padding: 20px;
  width: 100%;
  height: 87%;
  display: none;
}

.active-content {
  display: block;
}

.form-group {
  padding: 1px;
}

.input-group {
  padding: 5px;
}

.btn-success::after {
  color: #fff;
  background-color: #FF6800;
  border-color: #FF6800;
}

.btn-success:hover {
  color: #fff;
  background-color: #FF6800;
  border-color: #FF6800;
}

.line-selection-1 {
  display: flex;
  height: 47px;
}

.line-selection-2 {
  display: flex;
  padding-top: 16px;
}

.line-selection-pull {
  padding-right: 20px;
}

.container {
  padding-left: 5px;
  padding-right: 5px;
}

.pulldown-Firstline {
  padding: 0;
  padding-top: 4px;
}

select.form-control.undisplay-border {
  border: none;
}

.search {
  background-color: #fff;
  padding-right: 28px;
  border-radius: 5px
}

.search-area {
  position: relative;
  width: 100%
}

.search-area input {
  height: 46px;
  border: none;
  width: 96.5%;
  padding-left: 10px;
  border-left: 2px solid #ced4da;
  margin-left: 5px;
}

span.css-1u9des2-indicatorSeparator{
  background-color: #fff;
}


.search-area input:focus {
  border-color: none;
  outline: none;
}

.search-area input:active {
  border-color: none;
  border-radius: 4px;
  box-shadow: 01px;
}

.search-area i {
  position: absolute;
  top: 12px;
  left: -10px;
  color: #eee
}

.search-area button {
  position: absolute;
  top: 4.5px;
  right: -22.5px;
  border: none;
  height: 80%;
  background-color: #FF6800;
  color: #fff;
  width: 90px;
  border-radius: 4px;
}

/* Start Publish css */
/* -------------------------------------------------------- */
.btn-secondary {
  margin-right: 10px;
}

/* -------------------------------------------------------- */
/* End login,SignUp  modal css */

/* Start Publish css css */
/* -------------------------------------------------------- */
.modal-dialog {
  max-width: 300px;
}

.modal-header {
  border-bottom-color: #FF6800;
  border-bottom-width: medium;
}

.citys-column {
  display: flex;
}

.citys-column-child {
  padding: 10px;
}

.red-note {
  color: red;
}

.left-align {
  padding-top: 15px;
  padding-bottom: 15px;
  padding-right: 30px;
  display: flex;
  align-items: right;
  justify-content: right;
}

.left-align_label-left {
  padding-left: 15px;
  font-size: medium;
}

.left-align_label-right {
  font-size: medium;
}

.select-theme {
  margin-top: 20px;
}

.select-theme p {
  margin: 0 0 5px 0;
  padding: 0;
  font-weight: 500;
}

.radio-label {
  color: #303030;
  font-size: medium;
  font-weight: 400;
  margin-right: 7px;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  width: 27%;
}

.radio-input {
  margin: 0;
  visibility: hidden;
}

.radio-input:checked+span {
  border: 2px solid #FF6800;
}

.radio-input:checked+span:after {
  opacity: 1;
}

/* for outner filled circle */
.custom-radio {
  left: -8px;
  top: 5px;
  cursor: pointer;
  width: 20px;
  height: 20px;
  border: 2px solid #9a9a9a;
  border-radius: 50%;
  display: inline-block;
  position: relative;
}

/* for inner filled circle */
.custom-radio::after {
  content: '';
  width: 13px;
  height: 13px;
  background: #FF6800;
  position: absolute;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.2s;
}

.search-result-list {
  background-color: #423f3c;
  width: 26%;
  color: #fff;
  padding-top: 5px;
  padding-left: 10px;
  border-radius: 0.22rem 0.22rem 0.22rem 0.22rem;

}

.block-result {
  position: relative;
  border: 1px solid transparent;
  box-shadow: 0 0 10px rgba(135, 134, 134, 0.5);
  margin-top: 20px;
  border-radius: 0.22rem 0.22rem 0.22rem
}

.block-result::before {
  content: "";
  display: block;
  position: absolute;
  top: -5px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% + 2px);
  height: 6px;
  background: #364247;
  border-radius: 0.22rem 0.22rem 0 0;
}

.block-result-inner {
  margin-bottom: 6px;
  margin-top: 5px;
}

.block-result-detail_section-1 {
  margin-top: 40px;
}

.block-result-detail_section-2 {
  margin-top: 40px;
}

.block-result-detail {
  position: relative;
  border: 1px solid transparent;
  margin-top: 90px;
  margin-left: 15%;
  margin-right: 15%;
  border-radius: 0.22rem 0.22rem 0.22rem;
  height: 100%;
}

.section_h2 {
  background-color: #fff;
}

.section_h2 .section_h2-header {
  margin-top: 100px;
  background-size: 100%;
  background-image: linear-gradient(to bottom, #ffffff, #ffffff 2%, #eeeeee 3%, #ffffff 40%);
  padding-top: 10px;
  border-top: 2px solid #d4d0d0;

  .modulehead {
    padding-top: 10px 14px;
  }
}

.honepage-section_h2-header {
  margin-top: 50px;
}

.publist-content .section_h2-header_block1 {
  margin-top: 0;
  padding: 10px;
}

.publist-content .section_h2-header_block2 {
  margin-top: 30px;
  padding: 10px;
}

.title_display_default {
  padding: 8px 0 5px 8px;
  line-height: 1;
  border-left: 5px solid #FF6800;
  font-size: 20px;
  font-weight: bold;
  color: #364247;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.section_h2 .section_h2-body {
  margin-top: 20px;
  border-left: 1px solid #d9d9d9;
  border-right: 1px solid #d9d9d9;
  border-radius: 0.1rem;
}

.modulebody {
  padding: 0 14px;
  margin-top: 5px;
}

.section_h2 .section_h2-title {
  padding: 8px 0 5px 8px;
  line-height: 1;
  border-left: 5px solid #FF6800;
  font-size: 16px;
  font-weight: bold;
  color: #364247;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.section_h2 .section_h2-body {
  margin-top: 20px;
}

.inquiry_info {
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 0.1rem;
  color: #333;
}

.inquiry_info .inquiry_info-name {
  font-size: 14px;
  font-weight: 500;
}

.inquiry_info .inquiry_info-number {
  color: #333;
  font-weight: bold;
  font-size: 18px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}

.smartphone-number {
  margin-left: 10px;
}

.inquiry_info .inquiry_info-number:before {
  content: "";
  margin-right: 10px;
}

.FaLowVision_ViewNumber {
  margin-top: -3px;
  margin-left: 3px;
}

.display_view_number {
  font-size: larger;
  color: #483d8b;
}

.detailtable {
  width: 100%;
  table-layout: fixed;
  border-bottom: 1px solid #d9d9d9;
}

tbody {
  display: table-row-group;
  vertical-align: middle;
  border-color: inherit;
}

.detailtable .detailtable-title {
  padding-left: 20px;
  padding-right: 20px;
  background-color: #80808013;
  font-size: 14px;
  font-weight: bold;
  width: 180px;
  border-top: 1px solid #cfcfcf;
}

.detailtable .detailtable-body {
  padding: 20px 0 15px 20px;
  font-size: 14px;
  border-top: 1px solid #d9d9d9;
}

.table-td-price {
  color: #f93d04;
  font-weight: 600;
}


span.bds-type {
  border-color: #2c70a4;
  color: #fff;
  background-color: #397db2;
  padding: 2px 15px;
  margin-left: 14px;
}

a.bds-view-detail {
  position: absolute;
  top: 62%;
  right: 0;
  color: #003af6;
  padding: 2px 15px;
  text-decoration: underline;
  font-weight: bold;
  font-size: smaller;
}

.div-infos-inform {
  margin-top: 20px;
  margin-bottom: 20px;

}

.infos-inform {
  font-size: large;
  font-weight: 600;
  border-left: 5px solid #FF6800;
  padding-left: 20px;
}

.container-instruction-parent{
  /* border: 1px solid #FF6800; */
  padding-right: 10px;
  padding-left: 10px;
  padding-top: 15px;
  border-radius: 5px;
}

.container-contact-parent{
  border: 1px solid #FF6800;
  padding-right: 150px;
  padding-left: 150px;
  padding-top: 30px;
  border-radius: 5px;
  width: 100%;
  align-items: center;
  justify-content: center;
  float: center;
}

.contact-input {
  margin-top: 20px;
}

.sent-contact-btn {
  float: right;
  margin-bottom: 15px;
  width: 100%;
}

.container-instruction-child{
  padding-right: 20px;
  padding-left: 20px;
}
.container-instruction-child a{
  color: #000;
  text-decoration: underline;
}

.container-instruction-child a:hover{
  color: #FF6800;
}

.title_view-detail {
  background-color: #f7fafa;
  font-weight: bold;
  padding: 8px 0 5px 8px;
  line-height: 1;
  border-color: #FF6800;
  font-size: large;
  font-weight: bold;
  color: #364247;
  display: flex;
  border-radius: 4px;
}

.title_view_detail-text {
  margin-left: 10px;
}

.click-like-estates {
  position: relative;
  top: 24px;
  right: 180px;
  border-radius: 3px;
  width: 99px;
  padding: 6px;
  font-size: 14px;
  color: #FF6800;
}

.click-like-estates:hover {
  text-decoration: none;
  color: #FF6800;
}

.click-like-estates_inactive {
  position: relative;
  top: 25px;
  right: 180px;
  border-radius: 3px;
  width: 99px;
  padding: 6px;
  font-size: 14px;
  color: rgb(147 144 144);
}

.click-like-estates_inactive:hover {
  text-decoration: none;
  color: rgb(147 144 144);
}
.yeuthich {
  color: #FF6800;
}

.day_create_view_detail {
  padding: 5px;
  text-align: right;
  font-size: 14px;
}

.title-bds {
  color: #003af6;
  font-weight: bold;
  font-size: medium;
  line-height: 1.4;
  margin-left: 10px;
  text-decoration: underline;
}

.photo-spe-line {
  display: flex;
}

.bds-photo-lefthand {
  margin-bottom: 3px;
  margin-right: 6px;
  height: 121px;
}

.bds-photo-lefthand .photo {
  margin-bottom: 6px;
  background-color: #fff;
  text-align: center;
  cursor: pointer;
  overflow: hidden;
  width: fit-content;
}

.display-images-number {
  position: relative;
  float: left;
  left: 7px;
  top: -30px;
}

.display-images-number-no {
  position: relative;
  float: left;
  left: 10px;
  top: -32px;
  color: rgb(223 218 213);
}

.bds-photo-lefthand .photo img {
  width: 141px;
  height: 118px;
  border: 1px solid #003af6;
  object-fit: cover;
  /* object-fit: contain; */
}

.bds-photo-lefthand .photo img:hover {
  border: 1px solid #FF6800;
}

.bds-spec-righthand {
  border: 1px solid #b3bebe;
  width: -webkit-fill-available;
  height: -webkit-fill-available;
}

.verticalTable {
  width: 100%;
}

.verticalTable tbody tr {
  height: 39px;
}

.verticalTable tbody tr:not(:last-child) {
  border-bottom: 1px solid #b3bebe;
}

.verticalTable tbody tr th:not(:first-child) {
  border-left: 1px solid #b3bebe;
}

.three-column {
  width: 20px;
}

.verticalTable th {
  width: 15%;
  color: #425258;
  background-color: #80808013;
  font-size: 76.9%;
  font-weight: normal;
  padding-left: 10px;
}

.verticalTable td {
  width: 37%;
  border-left-width: 0;
  background-color: #fff;
  font-size: 76.9%;
  padding-left: 10px;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.unitSummary {
  width: 100%;
  border-top: 1px solid #bdc8ca;
  border-bottom: 1px solid #bdc8ca;
}

.unitSummary tr {
  height: 28px;
}

.unitSummary thead tr th {
  border: 0;
  color: #425258;
  background-color: #80808013;
  font-size: 76.9%;
  font-weight: normal;
  border-bottom: 1px solid #bdc8ca;
  padding-left: 20px;
}

.unitSummary thead tr th:last-child {
  text-align: right;
  padding-right: 30px;
}

.unitSummary tbody tr td:last-child {
  text-align: right;
  padding-right: 30px;
}

.unitSummary tbody tr td {
  font-size: 76.9%;
  padding-left: 20px;
}

.slideshow-display {
  margin: 20px;
  display: flex;
  justify-content: center;
  max-width: 100%;
}

.map-sub-title {
  font-size: medium;
  font-weight: bold;
}

.radio-align-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.recaptcha-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 5vh;
  margin-bottom: 15px;
}

.recaptcha-small {
  transform: scale(0.5);
}

.modal-form {
  padding: 0;
}

.modal-header {
  color: #FF6800;
  font-size: 20px;
}

.modal-title{
  font-size: larger;
}

.modal-body {
  border-top: 5px solid #FF6800;
  padding-right: 6%;
  padding-left: 6%;
}

.form-group{
  padding-top: -40px !important;
}

.modal-footer {
  height: 50px;
}

.modal-text {
  font-weight: 500;
  font-size: 16px;
}

.register-link {
  font-size: small;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
}

.area-m2 {
  position: relative;
  top: 30px;
  right: 10px;
  float: right;
}

.unit-billion {
  position: relative;
  top: 30px;
  right: 10px;
  float: right;
}

.unit-billion-totext {
  position: relative;
  top: 0;
  left: 0;
  float: left;
  color: #f93d04;
}

.mypage-div-right{
  position: relative;
  right: -15%;
  height: 136px;
  width: 200px;
  background-color: #ffe1e19c;
  float: right;
  display: block;
  padding: 5px;
}

.mypage-div-right-item{
  margin: 6px;
  background-color: #f5f5f5;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.mypage-div-right-item a:hover {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.5);
}

.mypage-div-right-item_1{
  display: flex;
  color: rgb(0 0 0);
  font-weight: 450;
}

.mypage-div-right-item_1 .logout-link {
  margin-left: 10px;
}

.logout-link:hover {
  color: rgb(0 0 0);
}

.mypage-div-right-item_1 .nav-link {
  padding: 0 !important;
  font-weight: 450;
}

.mypage-div-right-item{
  border-left: 5px solid #FF6800;
}

.mypage-div-right-item_none{
  border-left: none;
}

.footer-container {
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
  /* z-index: 999; */
}

.container-mypage {
  display: flex;
  width: 100%;
}

.mypage-div-profile {
  height: 400px;
  width: 88%;
  padding-top: 20px;
  padding-bottom: 30px;
  padding-left: 100px;
  padding-right: 100px;
  border-radius: 0.5rem;
  font-weight: bold;
  font-size: larger;
}

.mypage-div-content {
  height: 100%;
  width: 88%;
  border: 1px solid #FF6800;
  padding-top: 20px;
  padding-bottom: 30px;
  padding-left: 100px;
  padding-right: 100px;
  border-radius: 0.5rem;
  font-weight: bold;
  font-size: larger;
}

div.modal-form.form-group {
  margin-top: -15px;
}

div#signup.modal-form.form-group {
  margin-top: -15px;
  margin-bottom: -10px;
}

.form-control.currentpassword {
  width: 50% !important;
}

.form-control.login-info {
  padding-left: 44px;
  
}

.form-control.signup-info {
  padding-left: 44px;
}

input#login-repassword.form-control{
  margin-top: -50px;
}

input#username.login-info.form-control {
  border: 1px solid #f68429;
}

input#login-password.login-info.form-control {
  border: 1px solid #f68429;
}

input#name.signup-info.form-control {
  border: 1px solid #f68429;
}

input#password.signup-info.form-control {
  border: 1px solid #f68429;
}
input#signup-repassword.signup-info.form-control {
  border: 1px solid #f68429;
}

input#email.signup-info.form-control {
  border: 1px solid #f68429;
}


input#signup-repassword.signup-info.form-control {
  position: relative;
  top: -20px;
}

input#login-password.form-control {
  margin-top: -20px;
}

input#email.signup-info.form-control {
  position: relative;
  top: -32px;
}

.signup-email-icon {
  z-index: 1;
  position: relative;
  margin-top: 15px;
  left: 10px;
}

.login-icon {
  position: relative;
  top: 31.5px;
  left: 10px;
}

.login-icon-password {
  position: relative;
  top: 12px;
  left: 10px;
}

.signup-icon {
  position: relative;
  top: 31.5px;
  left: 10px;
}

.signup-repassword-icon {
  position: relative;
  top: 11px;
  left: 10px;
  z-index: 1;
}

.mypage-div-button {
  float: right;
}

.mypage-div-content button {
  float: right;
}

.show-password-entered {
  position: relative;
  top: -31px;
  right: 10px;
  float: right;
}

.show-repassword-entered {
  position: relative;
  top: -52px;
  right: 10px;
  float: right;
}

.mypage-div-content .text-danger{
  color: red !important;
  font-size: medium;
  font-weight: 400;
}

.im-not-robot{
  color: red !important;
  font-size: medium;
  font-weight: 400;
  text-align: center;
}

.mypage-div-content-profile {
  height: 400px;
}

.message-susses-area {
  width: 100%;
  padding: 10px;
  background-color: #d4edda;
  color: #155724;
  border-radius: 5px;
  font-size: medium;
  border-color: #c3e6cb;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-weight: 400;
}
a.click-to-liked {
  position: relative;
    top: 40px;
    right: 230px;
    border-radius: 3px;
    width: 99px;
    padding: 6px;
    font-size: 13px;
    color: #FF6800;
}
a.click-to-liked:hover {
  text-decoration: none;
}

.register-link-tag-a {
  margin-left: 5px;
}

.price-estate-converted {
  font-size: small !important;
  font-weight: 600;
  color: #f93d04;
}

.signup-recaptcha-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 6vh;
  margin-bottom: 15px;
  justify-items: center;
  align-items: center;
}

span#repassword.text-danger {
  position: relative;
  top: -21px;
}

span#email.text-danger{
  position: relative;
  top: -32px;
  bottom: -30px;
}

.icon-search-homepage {
  display: none;
}

.copyright-container {
  background-color: #fff;
  border-top: 1px solid #FF6800;
  color: #000;
  padding: 20px 7%;
  font-size: small;
}

svg.svg-inline--fa.icon-footer {
  margin-top: 2px;
  margin-right: 10px;
  position: relative;
  width: 20px;
  margin-left: -40px;
}

.isNoLZ {
  grid-gap: 0 !important;
}

.hQJCof {
  margin-top: 20px;
}

.mobile-menu-link {
  font-size: medium;
  color: #FF6800;
  font-weight: 450;
}

.container-rule-parent {
  background-color: #fff;
  font-size: 14px;
  border-color: 1px solid #fff;
  padding: 20px 15px;
  border-radius: 0.3rem;
  line-height: 28px;
  display: table;
}

.rule-use-title-lv1 {
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: bold;
  line-height: 28px;
  color: #3C3241;
  border-bottom: 3px solid #FF6800;
  font-weight: bold;
}

.rule-use-title-lv2 {
  margin-top: 10px;
  margin-bottom: 15px;
  font-size: 16px;
  font-weight: bold;
  line-height: 28px;
  color: #3C3241;
  font-weight: bold;
  display: grid;
}

.rule-use-content-lv2 {
  font-size: 14px;
  font-weight: 400;
  margin-top: 10px;
  margin-bottom: 10px;
  line-height: normal;
  display: grid;
}

.estates-select .css-1xc3v61-indicatorContainer {
  padding: 0px !important;
}

.div-active-mail {
  border: 1px solid #FF6800;
  font-size: x-large;
  color: #FF6800;
  padding: 8%;
  height: 200px;
  text-align: center;
}

.div-guideline {
  font-size: medium;
  color: #000;
  padding: 20px;
}
.div-guideline a:hover {
  border: 1px solid #FF6800;
  font-size: medium;
  color: #FF6800;
  padding: 20px;
}

/* -------------------------------------------------------- */
/* End login,SignUp  modal css */

/* smartphone setting css */
@media screen and (max-width:768px) {
 
  .btn-search-homepage {
    display: none;
  }
  
  .icon-search-homepage {
    display: block;
    position: absolute;
    top: 8px;
    float: right;
    right: -22px;
    fill: #FF6800;
    transition: fill 0.3s ease;
  }

  .icon-search-homepage:hover {
    fill: #ff9900;
    cursor: pointer;
  }

  .search-area input {
    width: 90%;
  }

  .MuiIconButton-root{
    padding: 7px !important;
  }
  .navbar-customize {
    display: block;
  }

  .user-circle-icon {
    margin-top: 5px;
    margin-right: 0 !important;
    display: block;
    position: relative;
    right: 170px
  }

  .MuiAppBar-positionFixed{
    display: block !important;
    position: relative !important;
    left: -7px !important;
    top:  -15px !important
  }

  .nav-bar{
    display: none !important;
  }

  .MuiAppBar-positionFixed{
    left: 0 !important;
  }
  .MuiAppBar-colorPrimary{
    background-color: #fff !important;
    width: 70px !important;
    background: rgba(255, 255, 255, 0) !important;
    border-bottom: none !important;
  }

  .MuiPaper-elevation4{
    box-shadow: none !important;
  }
  .MuiSvgIcon-root{
    fill: #FF6800 !important;
    width: 2em !important;
    height: 2em !important;
  }

  .MuiDivider-root{
    background-color: #FF6800 !important;
  }

  .MuiTypography-displayBlock{
    color: #FF6800;
    font-weight: 500 !important;
  }

  .MuiToolbar-gutters{
    padding-top: 3px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  
  .MuiToolbar-regular {
    min-height: 0 !important;
  }
  .div-right-login {
    background-color: #fff;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-right: 10px;
    padding-left: 10px;
    color: #FF6800;
    border-radius: 5px;
    font-size: medium;
    border: 1px solid #FF6800;
  }

  .container-right {
    top: 0;
    right: 0;
  }

  .mypage-div-right{
    margin: 10px;
    height: 136px;
    width: 93%;
    background-color: #ffe1e19c;
    left: 0 !important;
    float: left !important;
  }

  .page-container-for-rent-invest {
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 30px;
  }

  .logout_login-btn {
    display: none;
  }

  ul.header span.register {
    color: #fff;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-right: 10px;
    padding-left: 10px;
    color: #FF6800;
    border-radius: 5px;
    font-size: medium;
    border: 1px solid #FF6800;
    position: absolute;
    right: 42%;
    top: 17px;
}

  .page_container_search {
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 5px;
    padding-bottom: 25px;
    margin-top: 20px;
    margin-left: 0%;
    margin-right: 0%;
  }

  .page-container-common {
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    margin-left: 3px !important;
    margin-right: 3px !important;
    margin-top: 30px !important;
  }

  .header-content {
    display: none;
  }

  .citys-column {
    display: inline-grid;
    min-width: -webkit-fill-available;
  }

  .logo {
    display: none;
  }

  .modal.show .modal-dialog {
    margin: 13%;
  }

  .view-recent-photo-lefthand {
    width: 100%;
    height: 100%;
  }

  span.view-recent-child-right_cost {
    font-size: large !important;
    height: 10px !important;
  }

  .view-recent-child-right_address {
    color: #212529 !important;
    font-size: smaller !important;
  }

  .view-recent-child-right_column2 {
    margin-left: 10px !important;
    width: 20% !important;
  }

  .view-recent-child-right {
    margin-left: 15px !important;
  }

  .view-recent-child-right_construct {
    font-size: smaller !important;
  }

  a.link-to-view-recent-page {
    padding: 5px !important;
  }

  .view-recent-child-right_column3 {
    margin-right: 5px !important;
    margin-left: 15px;
  }

  .view-recent-child-right_area {
    font-size: smaller !important;
  }

  .block-result-detail {
    position: relative;
    margin-top: 20px !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    height: 100%;
  }

  .detailtable .detailtable-title {
    padding: 10px !important;
    width: 100px !important;
  }

  #gallery-container {
    width: 100% !important;
  }

  .slideshow-display {
    margin: 0 !important;
    margin-top: 3px !important;
  }

  .section_h2 {
    padding-left: 5px;
    padding-right: 5px;
  }

  .section_h2 .section_h2-header {
    margin-top: 0 !important;
  }

  .mapbox-container {
    margin-top: 30px !important;
  }

  .mapboxgl-map {
    width: 99vw !important;
    padding-left: 4px !important;
  }

  .detailtable .detailtable-body {
    padding: 10px !important;
    font-family: Arial !important;
    font-weight: 500 !important;
  }

  .fa-heard {
    position: relative;
    right: 233px !important;
    top: 67px !important;
  }

  .div-right-publish {
    position: absolute;
    right: 5px;
    top: 17px;
    background-color: #FF6800;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-right: 15px;
    padding-left: 15px;
    color: #FFF;
    border-radius: 5px;
    font-size: medium;
    width: 95px;
  }

  .div-right-register {
    background-color: #fff;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-right: 10px;
    padding-left: 10px;
    color: #FF6800;
    border-radius: 5px;
    font-size: medium;
    border: 1px solid #FF6800;
  }

  .tabs {
    line-height: 1px;
    width: 100%;
    font-size: 'medium';
  }

  .div-header-content {
    position: relative;
    top: 16.5px;
    height: 30px !important;
    right: 150px;
  }

  .likes-estates-recent {
    margin-left: 18px;
  }

  .navbar {
    left: -10px;
    top: -7px;
  }

  .header-content li span.countViewHistory,
  .display-count-number-in-up_view {
    right: 45px;
  }

  .line-selection-2 {
    display: block;
    padding-top: 5px;
  }

  .content {
    padding: 5px !important;
    padding-top: 5px !important;
    height: 92% !important;
  }
  
  #estates-city-home.css-b62m3t-container {
    margin-top: 0px;
    width: 100%;
  }

  #estates-price-home.css-b62m3t-container {
    margin-top: -13px;
    width: 100%;
  }

  #estates-area-home.css-b62m3t-container {
    margin-top: -13px;
    width: 100%;
  }


  .form-group.col-md-4 {
    padding: 0 !important;
    padding-bottom: 0 !important;
  }

  .page-container-publish {
    margin-left: 3%;
    margin-right: 3%;
    margin-top: 30px;
    margin-bottom: 10px;
  }

  .radio-label {
    margin-right: 0 !important;
    width: 28%;
  }

  .div-right-logout {
    position: absolute;
    right: 57%;
    top: 17px;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-right: 15px;
    padding-left: 15px;
    color: #FF6800;
    border-radius: 5px;
    font-size: medium;
  }
  
  .tabs-search {
    height: 220.3px !important;
  }

  a.bds-view-detail {
    display: none;
  }

  .unitSummary thead tr th {
    text-align: left;
    padding-left: 20px !important;
  }
  .unitSummary tbody tr td {
    text-align: left;
    padding-left: 30px !important;
  }

  .honepage-section_h2-header {
    margin-top: 25px;
  }

  .container-instruction-parent{
    margin: 10px;
  }
  
  .container-contact-parent{
    padding-right: 30px;
    padding-left: 30px;
    padding-top: 15px;
  }

  .mypage-div-content {
    padding-left: 5px !important;
    padding-right: 5px !important;
    width: 50% !important;
  }

  .mypage-div-right{
    margin-left: 10px;
    margin-top: 0px;
  }

  .mypage-div-profile{
    width: 93% !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  .title_view_detail-text {
    margin-left: 3px !important;
  }
  
  .click-like-estates_view-recent {
    padding: 0px !important;
    top: 5px !important;
    right:-20px !important;
    float: right;
  }


  .day_create_view_detail{
    padding: 5px !important;
  }

  .click-like-estates {
    right: 170px !important;
    padding: 0px !important;
  }

  .click-like-estates_inactive {
    right: 170px !important;
  }
  
  .gallery-img {
    height: fit-content !important;
  }

  .photo-spe-line {
    height: 143px;
  }

  .bds-spec-righthand{
    height: 138px;
  }

  .recaptcha-container {
    height: 3vh !important;
    margin-bottom:13px !important;
  }

  .signup-recaptcha-container {
    height: 3vh !important;
    position: relative;
  }

  input#email.signup-info.form-control {
    top: -33px !important;
  }
  
  .signup-email-icon {
    top: 0px;
  }

  .modulehead {
    padding-left: 10px;
    padding-right: 10px;
  }

  span.bds-type {
    margin-left: 0px !important;
  }

  .modulebody {
    padding: 0 7px;
  }

  .bds-photo-lefthand {
    margin-bottom: 0px;
    margin-right: 7px;
  }

  .bds-photo-lefthand .photo img {
    height: 138px;
  }

  .verticalTable td {
    padding-left: 5px;
  }

  .verticalTable tbody tr:first-child {
    height: 55.56px;
  }

  .title-bds {
    font-size: smaller;
  }

  select#select-seach-abvande-1.form-control {
    position: relative;
    top: -10px !important;
  }
  select#select-seach-abvande-2.form-control {
    position: relative;
    top: -20px !important;
  }
  select#select-seach-abvande-3.form-control {
    position: relative;
    top: -30px !important;
  }

  .unitSummary thead tr th:last-child {
    padding-right: 20px;
  }
  
  .col-sm-4 {
    width: 100% !important;
  }

  .css-1qj8wmi-control {
    width: 154px !important;
}

.loading-spiner .absolute {
  top: 270px;
}
}

/* large screen setting css */
@media screen and (max-width: 1200px) and (min-width: 576px) {
  .container-search {
    max-width: -webkit-fill-available;
    margin-left: 5px;
    margin-right: 5px;
  }

  .icon-search-homepage {
    display: none;
  }

  .search-area button {
    display: block;
  }
  .page_container_search {
    margin-left: 3% !important;
    margin-right: 3% !important;
  }

  .col-sm-4 {
    max-width: 100% !important;
  }

  #estates-city-home.css-b62m3t-container {
    margin-top: -5px;
    margin-left: 0px;
    width: 100%;
  }

  #estates-price-home.css-b62m3t-container {
    margin-top: -21px;
    margin-left: 0px;
    width: 100%;
  }

  #estates-area-home.css-b62m3t-container {
    margin-top: -21px;
    margin-left: 0px;
    width: 100%;
  }


 }