.mapbox-container {
    margin-top: 50px;
}

.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 80px;
}

.App-header {
    background-color: #222;
    height: 150px;
    padding: 20px;
    color: white;
}

.App-intro {
    font-size: large;
}

.container-mapbox-inner {
    padding: 3px;
    background-color: #fff;
    width: 64px;
    height: 64px;
    border-radius: 10%;
    position: absolute;
    bottom: 8px;
    left: 8px;
    z-index: 2;
}

.streets-map {
    background-image: url('/satellite.jpg');
    width: 58px;
    height: 58px;
    border-radius: 10%;
}

.satellite-map {
    background-image: url('/streets.jpg');
    width: 58px;
    height: 58px;
    border-radius: 10%;
}

.mapboxgl-ctrl-bottom-right {
    display: none;
}

.mapboxgl-ctrl-bottom-left {
    display: none;
}