.uploaded-file {
    display: flex;
    border-color: 1px black;
}

.uploaded-files {
    justify-content: left;
    display: flex;
    margin-bottom: 20px;
}

.uploaded-file img {
    height: 80px;
    width: 100%;
}

.uploaded-file {
    margin-top: 10px;
    padding: 10px;
    display: flex;
    justify-content: flex-end;
}

.delete_image_uploaded {
    position: absolute;
    margin-top: -13px;
    margin-right: -13px;
    border: 2px solid red;
    border-radius: 50%;

}